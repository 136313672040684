import React from 'react';
import { Container } from 'components/common'
import { Layout, SEO } from 'components/common';
import { Wrapper, Flex } from '../components/theme/styles'
import { Link } from 'gatsby'
export default () => (
  <Layout>
    <Wrapper>
      <Flex as={Container}>
        <SEO title="404: Not found" location="/404" />
        <h1>You are missing</h1>
      </Flex>
      <Flex as={Container}>
        <p>You just hit a route that doesn&#39;t exist... <Link to="/">Back To Nature</Link></p>
        
      </Flex>
    </Wrapper>
  </Layout>
);
